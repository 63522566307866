.rule-summary-dialog {
   max-width: 800px;

   .modal-body {
      max-height: 680px;
      overflow-y: auto;
   }
}

.ruleset {
   margin-bottom: 20px;

   &:last-child {
      margin-bottom: 10px;
   }

   .rules {
      font-size: 14px;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
   
      &:first-child .info {
         border-top: 0 none;
      }
   
      .rule {
         user-select: none;
         border-top: 1px solid #dddddd;
         padding: 8px;
         font-size: 0;
         display: flex;
   
         > * {
            align-self: center;
            font-size: 14px;
         }
   
         .type {
            width: 12%;
            text-transform: uppercase;
         }
   
         .name {
            width: 76%;
            
            .documentation {
               margin-left: 10px;
            }

            .description {
               font-size: 12px;
               display: block;
               margin-top: 2px;
            }
         }
   
         .id {
            width: 12%;
            text-align: right;
            font-size: 12px;
         }
      }
   }
   
}

