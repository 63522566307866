@import "~bootstrap/scss/bootstrap";
@import "./styles/forms";
@import "./styles/buttons";
@import "./styles/dialogs";
@import "./styles/overrides";

html {
   overflow-y: scroll;
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-image: linear-gradient(164deg, rgba(137, 174, 196, 0.34), #fff, rgba(0, 117, 127, 0.19));
   background-repeat: no-repeat;
   background-attachment: fixed;
   font-size: 14px;
}
