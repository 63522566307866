:local(.dropdown) {
   & > button {
      display: block;
      height: calc(1.5em + 0.75rem + 2px) !important;
      padding: 0.375rem 0.75rem;
      color: #495057 !important;
      background-color: #fff !important;
      background-clip: padding-box;
      border-radius: 0 !important;
      border: 1px solid #ced4da;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
      text-align: left !important;
      min-width: 250px;
      position: relative;

      &:hover {
         color: #495057 !important;
         cursor: default !important;
         border-color: #ced4da;
      }

      &:focus {
         color: #495057 !important;
         border-color: #80bdff !important;
         outline: 0;
         box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
      }

      &:active:focus {
         border-color: #80bdff !important;
         box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
         background: #ffffff !important;
      }

      &::after {
         width: 10px;
         position: absolute;
         right: 10px;
         top: calc(50% - 2px);
      }
   }
}
