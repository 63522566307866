.checkbox {
   display: block;
   position: relative;
   padding-left: 30px;
   cursor: pointer;
   font-size: 14px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;

   input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
         background-color: #007bff;

         &::after {
            display: block;
         }
      }
   }

   .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      background-color: #eee;

      &::after {
         content: "";
         position: absolute;
         display: none;
         left: 9px;
         top: 5px;
         width: 5px;
         height: 10px;
         border: solid white;
         border-width: 0 3px 3px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
      }
   }

   &:hover input ~ .checkmark {
      background-color: #ccc;
   }
}

label.required::after {
   content: "*";
   color: #d9534f;
   margin-left: 3px;
   font-size: 18px;
   line-height: 14px;
}
label.partlyRequired::after {
   content: "**";
   color: #d9534f;
   margin-left: 3px;
   font-size: 18px;
   line-height: 14px;
}
label.threeRedStars::after {
   content: "***";
   color: #d9534f;
   margin-left: 3px;
   font-size: 18px;
   line-height: 14px;
}
label.konsekvensRequired::after {
   content: "****";
   color: #d9534f;
   margin-left: 3px;
   font-size: 18px;
   line-height: 14px;
}
